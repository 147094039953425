<template>

  <v-dialog
      v-model="dialog"
      width="500"
    >
    <template v-slot:activator="{ on }">
      <v-btn
        color="secondary" 
        :class="buttonClass"
        dark
        v-on="on"
        v-if="fab"
        fab 
      >
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>
      <v-btn
        color="secondary" 
        :class="buttonClass"
        dark
        v-on="on"
        v-else
      >
        {{ label }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="magazineId">Edit magazine</span>
        <span v-else>Add a magazine</span>
      </v-card-title>

      <v-card-text>

        <v-form>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field
                  label="Title"
                  v-model="magazine.title"
                  v-on:keyup.enter="save"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="EAN"
                  v-model="magazine.ean"
                  v-on:keyup.enter="save"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Publisher"
                  v-model="magazine.publisher"
                  v-on:keyup.enter="save"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>

</template>

<script>

  import Api from '../../api'

  export default {
    name: 'MagazineModal',

    components: {},

    props: {
      magazineId: {
        type: Number
      },
      buttonClass: {
        type: String
      },
      fab: {
        type: Boolean,
        default: ()=> false
      },
      icon: {
        type: String,
        default: ()=> 'mdi-pencil'
      },
      label: {
        type: String,
        default: ()=> 'New'
      }
    },

    data: () => ({
      magazine: {},
      dialog: false,
      overlay: false
    }),

    mounted(){
      if( this.magazineId )
        this.fetchMagazine()
    },

    methods: {
      fetchMagazine()
      {
        if(this.magazineId)
          Api.magazines.one(this.magazineId).then((res)=>{
            this.magazine = res.data.data
          })
      },
      save()
      {
        this.overlay = true

        var ws = this.magazineId ? 
                 Api.magazines.update(this.magazineId, this.magazine) : 
                 Api.magazines.create(this.magazine)
        
        ws.then((res)=>{
            
            console.log(res.data.data)

            this.overlay = false
            this.dialog = false
            this.$emit('save', res.data.data)

          })
          .catch((e)=>{
            this.overlay = false
            this.$store.commit('growl', {
              color: 'error',
              text: e.response.data.message
            })
          })

      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      magazineId(newValue)
      {
        if(newValue)
          this.fetchMagazine()
      }
    },

  }
</script>
