<template>
  
  <div>
    <editor-menu-bubble 
      class="menububble" 
      :editor="editor" 
      :keep-in-bounds="keepInBounds" 
      @hide="hideLinkMenu" 
      v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bold() }"
          @click.stop.prevent="commands.bold">
          <v-icon dark>mdi-format-bold</v-icon>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.italic() }"
          @click.stop.prevent="commands.italic">
          <v-icon dark>mdi-format-italic</v-icon>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.underline() }"
          @click.stop.prevent="commands.underline">
          <v-icon dark>mdi-format-underline</v-icon>
        </button>

        <button
          class="menububble__button"
          @click.stop.prevent="commands.heading({ level: 1})">
          <v-icon dark>mdi-format-header-1</v-icon>
        </button>

        <button
          class="menububble__button"
          @click.stop.prevent="commands.heading({ level: 2})">
          <v-icon dark>mdi-format-header-2</v-icon>
        </button>

        <button
          class="menububble__button"
          @click.stop.prevent="commands.heading({ level: 3})">
          <v-icon dark>mdi-format-header-3</v-icon>
        </button>

        <button
          class="menububble__button"
          @click.stop.prevent="commands.ordered_list">
          <v-icon dark>mdi-format-list-numbered</v-icon>
        </button>

        <button
          class="menububble__button"
          @click.stop.prevent="commands.ordered_list">
          <v-icon dark>mdi-format-list-bulleted-square</v-icon>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.sup() }"
          @click.stop.prevent="commands.sup">
          <v-icon dark>mdi-format-superscript</v-icon>
        </button>

        <form class="menububble__form" 
              v-if="linkMenuIsActive" 
              @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input class="menububble__input white--text py-2 px-2" 
                 type="text" 
                 v-model="linkUrl" 
                 placeholder="https://" 
                 ref="linkInput" 
                 @keydown.esc="hideLinkMenu"/>
          <v-btn color="success" icon
                 class="menububble__button"
                 @click="setLinkUrl(commands.link, linkUrl)">
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn color="error" icon
                 class="menububble__button"
                 @click="setLinkUrl(commands.link, null)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </form>

        <template v-else>
          <button
            class="menububble__button"
            @click="showLinkMenu(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }"
          >
            <v-icon dark>mdi-link</v-icon>
          </button>
        </template>

      </div>
    </editor-menu-bubble>

    <editor-content :editor="editor" 
                    class="mt-3 py-2 px-3 grey lighten-4 editor__content"/>
  </div>

</template>

<style type="text/css">
  .ProseMirror {
    font-size: 1.3em;
  }
  .ProseMirror:focus {
    outline-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s;
  }
  .menububble.is-active {
    opacity: 1;
    visibility: visible;
  }
  .menububble__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer;
  }
</style>

<script>

  import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
  import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'

  import Sup from '../../plugins/tiptap/commands/sup'

  export default {
    name: 'NoteEditor',

    components: {
      EditorMenuBubble,
      EditorContent,
    },

    props: {
      content: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      editor: null,
      keepInBounds: true,
      linkUrl: null,
      linkMenuIsActive: false,
      toggleMultiple: null
    }),

    computed: {},

    mounted(){
      this.loadEditor()
    },

    beforeDestroy() {
      this.editor.destroy()
    },

    methods: {
      loadEditor()
      {
        this.editor = new Editor({
          extensions: [
            new Blockquote(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new BulletList(),
            new OrderedList(),
            new ListItem(),
            new TodoItem(),
            new TodoList(),
            new Bold(),
            new Code(),
            new Italic(),
            new Link(),
            new Strike(),
            new Underline(),
            new History(),
            new Sup(),
          ],
          autoFocus: true,
          content: this.content,
          onBlur: this.onUpdate
        })
      },

      onUpdate()
      {
        this.$emit('update:content', this.editor.getHTML() )
      },

      /**
       * Links.
       */
      
      showLinkMenu(attrs)
      {
        var defaultValue

        if( attrs.href ){
        
          defaultValue = attrs.href
        
        } else {

          let selection = this.editor.selection,
              text = this.editor.state.doc.textBetween( selection.from, selection.to, ' ' )

          defaultValue = this.isValidUrl(text) ? text : ''

        }

        this.linkUrl = defaultValue
        
        this.linkMenuIsActive = true
        
        this.$nextTick(() => {
          this.$refs.linkInput.focus()
        })
      },

      hideLinkMenu()
      {
        this.linkUrl = null
        this.linkMenuIsActive = false
      },

      setLinkUrl(command, url)
      {
        command({ href: url })
        this.hideLinkMenu()
      },

      isValidUrl(string)
      {
        try {
          new URL(string)
        } catch (_) {
          return false
        }
        return true
      }

    },

    watch: {
      content(newContent)
      {
        newContent = newContent.replaceAll('<p></p>','')
        this.editor.setContent(newContent)
      }
    }
  }
</script>
