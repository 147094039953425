<template>

  <v-dialog v-model="dialog" :fullscreen="$mq=='sm'">

    <v-card>
      <v-card-title primary-title
          class="headline red darken-2 white--text">
        <span>Add a node</span>
      </v-card-title>

      <v-card-text>

        <v-form>
          <v-container>
            <v-row>

              <v-col cols="12">
                
                <Search :routing="false"
                        @selected="onSelect"
                        autofocus/>

              </v-col>

            </v-row>
          </v-container>
        </v-form>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <Loading :status="overlay"/>

  </v-dialog>

</template>

<script>

  //import Api from '../../api'

  import Loading from '../Loading'
  import Search from '../Search'

  export default {
    name: 'NodeModal',

    components: {
      Loading,
      Search
    },

    props: {
      
    },

    data: () => ({
      dialog: false,
      overlay: false
    }),

    mounted(){},

    methods: {
      close()
      {
        this.dialog = false
      },
      onSelect(selected)
      {
        this.$emit('selected', selected)

        this.close()
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {},

  }
</script>
