<template>
  
  <v-dialog v-model="deleteDialog"
            :max-width="modalMaxWidth">
    <template v-slot:activator="{ on }">
      <v-btn icon class="mr-2" v-on="on">
        <v-icon>mdi-delete-circle</v-icon>
      </v-btn>
    </template>
    <v-card color="red darken-2">
      <v-card-title class="headline white py-6">
        Do you want to delete this note?
      </v-card-title>
      <v-card-actions>
        <v-btn color="white" text @click="deleteDialog = false">
          <v-icon class="mr-2">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="deleteNote">
          <v-icon class="mr-2">mdi-check</v-icon>
          Yes, Delete!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

  import Api from '../../api'
  import utils from '../../utils'

  export default {
    name: 'NoteDeleteModal',

    mixins: [utils],

    components: {
    },

    props: {
      note: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      deleteDialog: false
    }),

    mounted(){},

    methods: {
      deleteNote()
      {
        Api.notes.delete(this.note.id).then(()=>{
            this.deleteDialog = false
            this.$emit('delete', this.note)
          })
      }
    }

  }
</script>
