<template>
  <v-navigation-drawer absolute right
                       :value="theValue"
                       class="grey lighten-4">
    <template v-slot:prepend>
      <div class="red lighten-4 px-4 py-3">
        <h3>
          <v-icon class="mr-1"
                  @click="hide"
                  color="red">
            mdi-close-circle
          </v-icon>
          Network Filters
        </h3>
      </div>
    </template>
    <div class="px-4 py-3">

      <p class="mb-0">Link weight</p>
      <v-slider min="1" max="5" v-model="filters.weight"></v-slider>

      <p class="mb-0">Model Types</p>
      <v-chip v-for="model in modelTypes"
              :key="model"
              class="mx-1 my-1"
              @click="filterModelType(model)"
              :color="modelTypeIsFiltered(model) ? 'primary':''">
        {{ model }}
      </v-chip>

    </div>
  </v-navigation-drawer>

</template>

<script>

  import _ from 'lodash'

  export default {
    name: 'GraphFilters',

    components: {},

    props: {
      value: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      filters: {
        types: [],
        weight: 1
      },
      modelTypes: [
        'Article',
        'Author',
        'Book',
        'Issue',
        'Magazine',
        'Note',
        'Quote',
        'Topic',
        'Website'
      ],
      theValue: false
    }),

    mounted(){},

    methods: {
      
      filterModelType(model)
      {
        if( !this.modelTypeIsFiltered(model) )
          this.filters.types.push(model)

        else
          this.filters.types = _.without(this.filters.types,model)
      },

      getFilters()
      {
        return this.filters
      },

      hide()
      {
        this.theValue = false
      },

      modelTypeIsFiltered(model)
      {
        return this.filters.types.indexOf(model) >= 0
      },

      show()
      {
        this.theValue = true
      },

      toggle()
      {
        this.theValue = !this.theValue
      }

    },

    computed: {
    },

    watch: {

      value(newValue)
      {
        this.theValue = newValue
      }
      
    },

  }
</script>
