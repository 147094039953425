<template>
  
  <v-container fluid>

    <v-card class="pt-5 lighten-5 mb-5" 
            :color="cardColor"
            :key="note.id"
            @click.prevent="onClick"
            :outlined="outlined">
      
      <v-card-text class="subtitle-1" 
                   v-html="note.content">
      </v-card-text>

      <v-card-actions>
        <v-list-item class="grow">
          <v-row
            align="center"
            justify="end"
          >

            <div v-if="showBookDetails"
                 @click.stop="openBook(note.owner)">
              <v-icon class="mr-2">mdi-book</v-icon> 
              <strong class="grey--text">{{ note.owner.title }}</strong>
            </div>

            <v-spacer></v-spacer>

            <div v-if="buttons">
              <FavouriteButton :status="isFavNote(note)"
                               model-type="note" 
                               :model-id="note.id" 
                               @toggled="onFavToggle"/>

              <NoteModal :note-id="note.id" 
                         only-icon
                         @save="onUpdate"/>

              <NoteDeleteModal :note="note" 
                               @delete="onDelete"/>
            </div>

          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>

  </v-container>

</template>

<script>

  import _ from 'lodash'
  import $ from 'jquery'
  //import Api from '../api'

  import FavouriteButton from '../FavouriteButton'
  import NoteModal from './Modal'
  import NoteDeleteModal from './DeleteModal'

  export default {
    name: 'Note',

    components: {
      FavouriteButton,
      NoteModal,
      NoteDeleteModal
    },

    props: {
      bookDetails: {
        type: Boolean,
        default: false
      },
      buttons: {
        type: Boolean,
        default: () => true
      },
      note: {
        type: Object,
        default: () => {}
      },
      outlined: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
    }),

    computed: {
      cardColor()
      {
        return this.isFavNote(this.note) ? 'yellow' : 'grey'
      },
      showBookDetails()
      {
        return this.bookDetails && this.note.owner
      }
    },

    mounted(){},

    methods: {
      isFavNote(note)
      {
        var favs = this.$store.getters.favourites.notes,
            check = _.chain(favs)
                     .filter({id:note.id} )
                     .isEmpty()
                     .value()
        return !check
      },
      onDelete(note)
      {
        this.$emit('delete', note)
      },
      onClick(e)
      {
        var isLink = $(e.target).attr('href')

        if(isLink)
          window.open(isLink)

        else
          this.$emit('click', this.note)
      },
      onFavToggle()
      {
        this.$store.dispatch('loadFavourites')
      },
      onUpdate(updatedNote)
      {
        this.$emit('update', updatedNote)
      },
      openBook(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      }
    }

  }
</script>
