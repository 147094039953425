<template>

  <v-btn fab dark @click="openNetwork">
    <v-icon>mdi-graph</v-icon>
  </v-btn>

</template>

<script>

  export default {
    name: 'ActionButton',

    components: {
    },

    props: {
      modelId: {
        type: Number,
        required: true
      },
      modelType: {
        type: String,
        required: true
      }
    },

    data: () => ({
    }),

    mounted(){},

    methods: {
      openNetwork()
      {
        let modelId = this.modelId,
            modelType = this.modelType

        this.$router.push({ 
          name: 'network', 
          params: { modelId, modelType } 
        })
      }
    },

    watch: {},

  }
</script>
