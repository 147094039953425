<template>

  <div>
    <d3-network :net-nodes="nodes" 
                :net-links="links" 
                :options="options"
                :nodeCb="nodeCb"
                @node-click="onNodeClick" 
                v-if="hasNodes"/>

    <v-btn fab dark
           id="dragger"
           ref="dragger" 
           draggable
           :class="hideOnDrag"
           @drag="onDrag"
           @dragstart="onDragStart"
           @dragend="onDragEnd"
           v-if="dragger">
      <v-icon>mdi-drag-variant</v-icon>
    </v-btn>

    <div id="move-controls"
         v-if="moveControls">
      <v-btn fab dark 
             v-for="direction in directions"
             :key="direction"
             :class="direction"
             @click="moveGraph(direction)">
        <v-icon>mdi-arrow-{{ direction }}-bold</v-icon>
      </v-btn>
    </div>

  </div>

</template>

<style type="text/css">
  #network-graph {
    height: 100vh;
    touch-action: manipulation;
  }
  /**
   * Dragger.
   */
  #dragger {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -28px;
    margin-left: -28px;
  }
  #dragger.ondrag {
    opacity: 0;
  }
  /**
   * Move controls.
   */
  #move-controls {
    position: fixed;
    bottom: 20px;
    left: 20px;
    -webkit-transition: all .3s;
    transition        : all .3s;
  }
  .v-application.nav-toggled #move-controls {
    left: 280px;
  }
  #move-controls button {
    position: absolute;
  }
  #move-controls button.up {
    bottom: 70px;
    left: 50px;
  }
  #move-controls button.down {
    left: 50px;
    bottom: 0;
  }
  #move-controls button.left {
    bottom: 35px;
  }
  #move-controls button.right {
    bottom: 35px;
    left: 100px;
  }
  /**
   * Node types.
   */
  /*.node.topic { fill: #ccc; }*/
  .node.book { 
    fill: #E8EFF9; 
    stroke: #3577CB;
  }
  .node.author {
    fill: #F7CFD3;
    stroke: #E25141;
  }
  /**
   * Labels.
   */
  .node-label {
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;
  }
</style>

<script>

  import * as d3 from "d3"
  import D3Network from 'vue-d3-network'

  export default {
    name: 'Graph',

    components: {
      D3Network
    },

    props: {
      dragger: {
        type: Boolean,
        default: true
      },
      nodes: {
        type: Array,
        required: true
      },
      links: {
        type: Array,
        required: true
      },
      moveControls: {
        type: Boolean,
        default: true
      },
      moveTick: {
        type: Number,
        default: 20
      },
      zoomTick: {
        type: Number,
        default: 0.2
      }
    },

    data: () => ({
      directions: ['left', 'up', 'right', 'down'],
      gravity: 0.8,
      hideOnDrag: '',
      movements: {
        x: 0,
        y: 0
      },
      options: {
        force: 1000,
        linkWidth: 3,
        nodeSize: 20,
        nodeLabels: true
      },
      scale: 1
    }),

    mounted(){},

    methods: {
      drag(e)
      {
        var z = d3.zoom().on("zoom", e => this.svgG.attr("transform", e.transform))

        this.svgG.transition()
                 .duration(750)
                 .call(z.translateBy, e.offsetX, e.offsetY)
      },
      getZoom()
      {
        var transform = d3.zoomIdentity
                          .translate(
                              this.movements.x,
                              this.movements.y
                            )
                          .scale(this.scale)

        console.log({
          translate: this.movements,
          scale: this.scale
        })

        const zoom = d3.zoom()
                       .on("zoom", ()=>{
                          this.svgG
                              .attr("transform", transform)
                        })

        return zoom
      },
      move(x,y)
      {
        var zoom = this.getZoom()

        this.movements.x += x
        this.movements.y += y

        this.$nextTick(()=>{
          this.svgG.transition()
                   .duration(750)
                   .call(zoom.translateBy, x, y)
        })
      },
      moveGraph(direction)
      {
        var tick = this.moveTick
        
        switch(direction){
          case 'up': this.move(0, tick); break;
          case 'down': this.move(0, -tick); break;
          case 'left': this.move(tick, 0); break;
          case 'right': this.move(-tick, 0); break;
        }
      },
      nodeCb(node)
      {
        node._cssClass = node.id.split('-').shift()
        return node
      },
      onDrag()
      {
        //this.drag(e)
      },
      onDragStart(e)
      {
        this.hideOnDrag = "ondrag"

        const empty = document.createElement("img")
        
        e.dataTransfer.setDragImage(empty, 0, 0)
      },
      onDragEnd(e)
      {
        this.hideOnDrag = ""
        this.drag(e)
      },
      onNodeClick(ev, node)
      {
        this.$emit('node-click', ev, node)
      },
      toggleDragger()
      {
        this.hideOnDrag = this.hideOnDrag ? '' :
                          'ondrag'
      },
      zoom(zoom=1)
      {
        var k = this.zoomTick * zoom,
            z = this.getZoom()

        this.scale += k

        this.$nextTick(()=>{
          this.svgG.transition()
                 .duration(750)
                 .call(z.scaleBy, k)
        })
      },
      zoomIn(zoom=1)
      {
        this.zoom(zoom)
      },
      zoomOut(zoom=1)
      {
        this.zoom( zoom * -1 )
      }
    },

    computed:
    {
      hasNodes()
      {
        return this.nodes.length
      },
      svg()
      {
        const svg = d3.select("svg.net-svg")
        
        return svg 
      },
      svgG()
      {
        const g = d3.selectAll('svg.net-svg > g')

        return g
      }
    },

    watch: {
      
    },

  }
</script>
