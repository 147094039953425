<template>
  
  <v-container fluid>

    <template v-for="(note) in sortedNotes">

      <Note :note="note" 
            :key="note.id"
            :outlined="outlined"
            @delete="onDelete" 
            @update="onUpdate"
            @click="onNoteClick"/>
      
    </template>

    <Message v-if="!sortedNotes.length && emptyMessage">
      No notes here.
    </Message>

  </v-container>

</template>

<script>

  import _ from 'lodash'
  import Api from '../../api'

  import Message from '../Message'
  import Note from './Note'

  export default {
    name: 'Notes',

    components: {
      Message,
      Note
    },

    props: {
      emptyMessage: {
        type: Boolean,
        default: true
      },
      modelId: {
        type: Number,
        required: true
      },
      modelType: {
        type: String,
        required: true
      },
      outlined: {
        type: Boolean
      }
    },

    data: () => ({
      notes: []
    }),

    computed: {
      sortedNotes()
      {
        var sorted = this.notes

        return sorted
      }
    },

    mounted(){
      this.fetchNotes()
    },

    methods: {
      onDelete(note)
      {
        this.notes = _.reject( this.notes, note )
        this.$emit('delete', note)
      },
      fetchNotes()
      {
        var ws

        switch(this.modelType){
          case 'article':
            ws = Api.articles.notes(this.modelId)
            break;
          case 'book':
            ws = Api.books.notes(this.modelId)
            break;
          case 'issue':
            ws = Api.issues.notes(this.modelId, true)
            break;
          case 'quote':
            ws = Api.quotes.notes(this.modelId)
        }

        if(ws)
          ws.then((res)=>{
            this.notes = res.data.data
          })
      },
      onNoteClick(note)
      {
        this.$emit('noteClick', note)
      },
      onUpdate(note)
      {
        var index = _.findIndex(this.notes, {id:note.id})
        this.notes.splice(index, 1, note)
      }
    },

    watch: {
      modelId()
      {
        this.fetchNotes()
      }
    }

  }
</script>
